import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { UserVerificationStatusEnum } from '@/core/packages/shared-library';
import AccountMenuDetailsMobileComponent from '@/modules/account/components/AccountMenuDetailsMobileComponent.vue';
import { USERS_STORE } from '../../../store/modules/users';
export default defineComponent({
    name: 'account-page',
    components: {
        AccountMenuDetailsMobileComponent,
    },
    computed: {
        ...mapGetters(USERS_STORE, ['accountStatuses', 'user', 'isUserVerificationActive']),
        getCurrentRoute() {
            return this.$route.name;
        },
        selectedMenu() {
            const currentRoute = this.$route;
            const findCurrentAccountDetailsMenu = this.menus.find((menu) => menu.name === currentRoute.name);
            const [firstMenu] = this.menus;
            return findCurrentAccountDetailsMenu || firstMenu;
        }
    },
    data() {
        return {
            menus: [
                { label: 'Account Status', name: 'account/details' },
                { label: 'Profile', name: 'account/public-profile' },
                { label: 'Business Details', name: 'account/business-details' },
                { label: 'Identification', name: 'account/identification-check' },
                { label: 'Certification', name: 'account/certification' },
                { label: 'Insurance', name: 'account/insurance' },
                { label: 'Skills', name: 'account/skills' },
            ]
        };
    },
    async created() {
        await this.getAccountStatuses();
    },
    methods: {
        ...mapActions(USERS_STORE, ['getAccountStatuses']),
        onClickMenu(e) {
            this.$router.push({ name: e.index });
            this.selectedMenu = e;
        },
        getAccountMenuStatus(formType, index) {
            const result = {
                type: 'primary',
                label: 'Not Started'
            };
            const formName = {
                TRADESPERSON: 0,
                PUBLIC_PROFILE: 1,
                BUSINESS_DETAILS: 2,
                IDENTIFICATION_CHECK: 3,
                CERTIFICATION: 4,
                INSURANCE: 5,
                SKILLS: 6,
            };
            // eslint-disable-next-line no-param-reassign
            formType = formType.replace('account/', '').replace('-', '_');
            let form = null;
            if (formType !== 'tradesperson' && this.accountStatuses && this.accountStatuses.forms[formType]) {
                form = this.accountStatuses.forms[formType] || null;
            }
            switch (index) {
                case formName.TRADESPERSON:
                    if (this.user.userVerificationStatus === '2' && this.accountStatuses.canBeVerified) {
                        result.label = 'Verified';
                        result.type = 'success';
                    }
                    else if ((this.user.userVerificationStatus === '1') && this.isUserVerificationActive) {
                        result.label = 'Pending Verification';
                        result.type = 'warning';
                    }
                    else if (this.isUserVerificationActive) {
                        result.label = 'Submit Details';
                        result.type = 'primary';
                    }
                    else {
                        result.label = 'Add Details';
                        result.type = 'primary';
                    }
                    break;
                default:
                    if (form && form === UserVerificationStatusEnum.PENDING_VERIFICATION) {
                        result.type = 'warning';
                        result.label = 'Pending Verification';
                    }
                    else if (form && form === UserVerificationStatusEnum.VERIFIED) {
                        result.type = 'success';
                        result.label = 'Complete';
                    }
                    else if (form && form === UserVerificationStatusEnum.REJECTED) {
                        result.type = 'danger';
                        result.label = 'Rejected';
                    }
                    break;
            }
            return result;
        }
    }
});
