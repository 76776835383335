import { defineComponent } from 'vue';
import ChipState from '@/core/components/ui/ChipStateDepracated.vue';
export default defineComponent({
    name: 'account-menu-details-mobile-component',
    components: {
        ChipState,
    },
    props: {
        selectedMenu: String,
        menus: Array,
        progressFn: Function,
    },
    emits: ['select-menu'],
    computed: {
        selectedMenuWithProps() {
            return this.progressFn(this.selectedMenu.name, this.menus.indexOf(this.selectedMenu));
        }
    },
    methods: {
        onSelectMenu(menu) {
            this.$emit('select-menu', menu);
        }
    },
});
